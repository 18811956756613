html, body, #root {
  min-height: 100vh;
  height: 100%;
}

.loew {
  & #multi-select-label {
    letter-spacing: -0.5px;
  }
}

.arial {
  & * {
    font-family: Arial, sans-serif;
  }
}